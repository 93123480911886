.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

/* carets for react-bootstrap-table-2 */
th .order-4:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

th .order-4:after {
  content: "\2193";
  opacity: 0.4;
}

th .caret-4-asc:before {
  margin-left: 3.5px;
  content: "\2191";
}

th .caret-4-asc:after {
  content: "\2193";
  opacity: 0.4;
}

th .caret-4-desc:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

th .caret-4-desc:after {
  content: "\2193";
}

.plan-box {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.15) !important;
}

#tooltip-register {
  max-width: 40vw !important;
  text-align: left;
}

.blurred-text-container {
  position: relative; /* Erstellt einen Positionskontext für das Tooltip */
}

.blurred-text {
  filter: blur(4px);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.tooltip-text {
  position: absolute;
  bottom: 100%; /* Positioniere das Tooltip über dem Textelement */
  left: 50%; /* Zentriere es horizontal */
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: black;
  color: white;
  border-radius: 5px;
  z-index: 1000;
  white-space: nowrap;
  opacity: 0; /* Starte unsichtbar */
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* Verhindert Interaktion mit dem Tooltip */
}

.blurred-text-container:hover .tooltip-text {
  opacity: 1; /* Zeige das Tooltip beim Hover */
}

.custom-searchbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 400px;
}

.custom-searchbar .search-label {
  flex: 1;
}
